@charset "utf-8";

// Our variables
$base-font-family: Helvetica, "Helvetica Neue", Arial, sans-serif;
$base-font-size: 16px;
$base-font-weight: 400;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;
$font-weight-light: 100;
$font-weight-normal: 400;
$font-weight-bold: 600;

$spacing-unit: 30px;

$text-color: #111;
$background-color: #fdfdfd;
$brand-color: #2a7ae2;

$grey-color: #828282;
$grey-color-light: #b1b1b1;
$grey-color-dark: darken($grey-color, 25%);

// Width of the content area
$content-width: 800px;

$on-palm: 550px;
$on-laptop: 780px;

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

.dont-break-out {
    /* Careful, this breaks the word wherever it is without a hyphen */
    overflow-wrap: break-word;
    word-wrap: break-word;

    /* Adds a hyphen where the word breaks */
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

@import "normalize", "base", "layout", "color", "syntax-highlighting";
